@import '../../../../scss/theme-bootstrap';

.content_block-product-card {
  .content-block__single-product,
  .content-block__product-carousel {
    @include breakpoint($medium-up) {
      padding: 10px 5px;
    }
    .editorial-product-grid {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    .product-brief {
      margin-bottom: 0;
      @include breakpoint($medium-up) {
        max-width: 310px;
        margin-bottom: 40px;
      }
      @include breakpoint($landscape-up) {
        max-width: unset;
      }
      &__add-to-bag {
        width: 100%;
        margin-top: 0;
      }
      &__image-link {
        @include breakpoint($medium-up) {
          min-height: unset;
        }
      }
      &__flag {
        @include breakpoint($medium-up) {
          height: auto;
          display: block;
        }
      }
      &__sized-buttons {
        min-height: auto;
      }
    }
    .mpp-grid__wrapper {
      width: 100%;
      margin: 0;
    }
    .mpp-grid__item {
      display: flex;
      justify-content: center;
      height: auto;
      overflow: visible;
      margin-bottom: 0;
      width: 100%;
      padding: 38px 0 0;
    }
  }
  .content-block__single-product {
    padding: 0;
    @include breakpoint($medium-up) {
      padding: 0;
    }
    .content-block--split-width & {
      padding: 12px 0 20px;
      overflow: auto;
      max-height: 80vh;
      height: 100%;
      @include breakpoint($medium-up) {
        max-height: unset;
        overflow: unset;
        height: auto;
      }
    }
    .mpp-grid__wrapper {
      @include breakpoint($medium-up) {
        margin: 0 20px;
      }
    }
    .mpp-grid__item {
      padding-bottom: 20px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
  }
  .content-block__product-carousel {
    padding: 0 20px;
    .product-brief__image-container {
      @include breakpoint($medium-up) {
        width: 100%;
        order: 1;
      }
    }
    .product-brief__product-info-wrapper {
      @include breakpoint($medium-up) {
        width: 70%;
        order: 2;
      }
    }
  }
  .content-block__product-grid {
    .mpp-grid__wrapper {
      @include breakpoint($medium-up) {
        margin: 0;
        width: 100%;
      }
    }
    @include breakpoint($medium-up) {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      .editorial-product-grid {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
      }
      .mpp-grid__item {
        display: flex;
        justify-content: center;
        height: auto;
        overflow: visible;
        margin-bottom: 60px;
        width: 100%;
      }
      .product-brief {
        display: block;
        &.IE-min-height-processed {
          min-height: auto;
        }
      }
      .product-brief__flag,
      .product-brief__abstract {
        text-align: center;
      }
      .product__button {
        float: none;
      }
      .product-brief__image-container {
        margin: 0 auto;
      }
      .product-brief-shades {
        padding: 0;
      }
      .product-brief-shades__grid {
        display: none;
      }
      .product-brief__product-info-wrapper {
        width: 100%;
        display: block;
        flex: none;
      }
      .product-brief__bottom {
        margin: 0 auto;
      }
      .product-brief__abstract,
      .product-brief__price {
        margin-bottom: 0;
      }
      .product-brief__bottom-mobile {
        .product-brief__add-to-bag {
          margin-top: 0;
          .product__button--add-to-bag {
            width: 100%;
            height: 100%;
            line-height: 1.3;
          }
        }
      }
      .product-brief__abstract {
        display: flex;
        flex-direction: column;
        height: auto;
      }
      .product-brief__short-desc-link {
        display: block;
      }
    }
  }
  .content-block__media-link {
    margin: 0 2px 2px;
    width: 100%;
    @include breakpoint($xlarge-up) {
      margin: 0 20px;
    }
    .button {
      width: 100%;
    }
  }
  .product-brief__headline {
    width: 100%;
  }
  .product-brief__shades {
    min-height: 32px;
    padding: 0;
    margin-bottom: 16px;
    max-width: 335px;
    @include breakpoint($medium-up) {
      max-width: none;
    }
  }
  .product-brief__image-container {
    margin: 0 auto;
    @include breakpoint($medium-up) {
      margin-bottom: 9px;
    }
    .product-brief__image-wrapper {
      min-height: auto;
      width: 100%;
    }
  }
  .product-brief-shades {
    &__header {
      margin-bottom: 16px;
      padding-#{$ldirection}: 0;
    }
    &__title {
      text-align: $ldirection;
    }
    &__name,
    &__badge {
      @include typography-link-text;
      display: inline-block;
      color: $color-gray-op65;
      text-transform: none;
    }
    &__grid {
      max-width: none;
      padding: 0 25px;
      .slick-prev {
        #{$ldirection}: 0;
      }
      .slick-next {
        #{$rdirection}: 0;
      }
      &.no-arrows {
        padding: 0;
        .slick-track {
          margin: 0;
        }
      }
    }
  }
  .product-brief__abstract {
    text-align: $ldirection;
    max-width: none;
    .product-brief {
      &__header {
        @include typography-link-text;
        text-align: $ldirection;
        margin-bottom: 4px;
        text-transform: none;
      }
      &__short-desc {
        @include typography-link-text;
        text-align: $ldirection;
        margin-bottom: 16px;
      }
      &__price {
        @include typography-link-text;
        text-align: $ldirection;
        margin-bottom: 20px;
      }
    }
  }
  .product-brief-shades__personalization {
    display: none;
  }
  .hide-badges {
    .product-brief__flag {
      display: none;
    }
  }
  .slick-slider {
    margin-bottom: 0;
    .product__notify-me {
      display: none;
    }
  }
  .carousel-controls {
    position: relative;
    display: flex;
    flex-direction: row;
    width: max-content;
    flex-wrap: wrap;
    padding: 2px 0 6px;
    margin: 0 auto;
  }
  .slick-dots {
    bottom: 0;
    position: relative;
    width: max-content;
    margin: 0 auto;
    li {
      width: 17px;
      margin: 0;
      button::before {
        font-size: 30px;
      }
    }
  }
  .carousel-dots,
  .carousel-prev,
  .carousel-next {
    position: relative;
    width: max-content;
  }
  .carousel-prev {
    flex: 1;
    order: 1;
  }
  .carousel-dots {
    order: 2;
    flex: 1;
    top: 0;
  }
  .carousel-next {
    order: 3;
    flex: 1;
  }
  .slick-prev {
    #{$ldirection}: 0;
  }
  .slick-next {
    #{$rdirection}: 0;
    &::before {
      #{$rdirection}: auto;
      position: relative;
      height: auto;
    }
  }
  .slick-next,
  .slick-next::before,
  .slick-prev,
  .slick-prev::before {
    font-size: 20px;
    line-height: 1.5;
    color: $color-black;
  }
  .content-block-product {
    &--mobile {
      display: block;
      @include breakpoint($large-up) {
        display: none;
      }
    }
    &--pc {
      display: none;
      @include breakpoint($large-up) {
        display: flex;
        height: 100%;
      }
    }
  }
  &.dark-background {
    .product-brief {
      &-shades {
        &__shade {
          box-shadow: $color-gray-op40 0 0 1px 1px;
        }
      }
    }
    @if $cr22 == false {
      .mpp-grid__item,
      .product-grid__item {
        border-color: $color-white-op15;
      }
      .product-brief {
        &__price {
          .price {
            color: $color-white-op50;
          }
          &.discount_price {
            .price {
              color: $color-white;
            }
            .old_price {
              color: $color-white-op50;
            }
          }
        }
        &__vto-cta,
        &__foundation-vto-cta {
          @include cta-primary-white;
          &:hover {
            background-color: $color-white;
            color: $color-core-black;
          }
          .icon--camera {
            fill: $color-core-black;
          }
        }
        &__header {
          color: $color-white;
        }
        &__shades,
        &__sizes {
          &-count {
            color: $color-white-op50;
            &:after {
              color: $color-white-op50;
            }
          }
        }
        &__abstract-item {
          color: $color-white-op50;
          &:after {
            color: $color-white-op50;
          }
        }
        &-shades {
          .slick-next:before,
          .slick-prev:before {
            color: $color-white;
          }
          &__name,
          &__badge {
            color: $color-white;
          }
          &__shade {
            border: none;
            box-shadow: none;
            &.selected,
            &:hover {
              border: 1px solid $color-core-black;
              box-shadow: $color-white 0 0 1px 2px;
            }
          }
          &__personalization {
            .ff-personalization__msg {
              color: $color-white;
            }
          }
        }
        &__short-desc,
        &__flag--subheader,
        &__price {
          color: $color-white-op50;
        }
        &__bottom {
          .product__button {
            @include cta-primary-white;
            &:hover {
              background-color: $color-white;
              color: $color-core-black;
            }
          }
        }
        &[data-rating]:not([data-rating='0.0'])
          .product-brief__image-container
          .product-brief__flag
          .product-brief__flag--subheader {
          border-color: $color-white-op50;
        }
        &__image-wrapper {
          .slick-dots {
            button {
              background-color: $color-white-op50;
            }
            .slick-active {
              button {
                background-color: $color-white;
              }
            }
          }
        }
        &__shade,
        &__sized {
          &-name {
            color: $color-white;
            &::before {
              filter: brightness(1) invert(1);
            }
            &:only-child {
              color: $color-white;
            }
          }
          &-arrow {
            filter: brightness(1) invert(1);
          }
        }
        &__sized-buttons {
          .sku-list__list {
            .sku-list__item {
              &:not(:only-child) {
                .sku-list__button {
                  &.button--active {
                    color: $color-white;
                    &:hover {
                      color: $color-white;
                    }
                  }
                }
              }
              .sku-list__button {
                color: $color-white-op50;
                &::before {
                  filter: brightness(1) invert(1);
                }
                &.button--active {
                  color: $color-white-op50;
                  &:hover {
                    color: $color-white-op50;
                  }
                }
              }
            }
          }
        }
        &__size {
          color: $color-white-op50;
        }
      }
      .p-w-r .pr-snippet-stars {
        filter: brightness(1) invert(1);
        background-color: transparent;
      }
      .product-full__review-snippet .pr-category-snippet__total {
        color: $color-white;
      }
    }
  }
}
